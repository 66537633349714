<div [loading]="!loaded" class="detail">
  <div class="mb-10 flexbox-container">
    <div class="flex-rest">
      <a class="button" uiSref="^"><i class="fa fa-chevron-left mr-5"></i>Seznam orgánů</a>
    </div>

    <div class="actions" *ngIf="dotcenyOrgan && canEdit()">
      <a (click)="onDotcenyOrganCancel()" *ngIf="!ValidationUtils.isNonEmptyArray(dotcenyOrgan.reseneUkony)" class="button red">
        <i class="fa fa-trash-o"></i>Smazat Orgán
      </a>
    </div>
  </div>

  <div *ngIf="loaded">
    <div style="display:flex">
      <h1>{{ dotcenyOrgan?.nazev }}</h1>
    </div>

    <div class="mt-30 mb-70 clearfix">
      <div class="column-33">
        <label>Adresa</label>
        <div class="info">{{ dotcenyOrgan.adresniMisto | address }}</div>

        <label>IČO</label>
        <div class="info">{{ dotcenyOrgan.ico }}</div>

        <label>Datová schránka</label>
        <div class="info">{{ dotcenyOrgan.datovaSchranka }}</div>

        <label>www</label>
        <div class="info"><span [innerHTML]="dotcenyOrgan.www | urlhighlight"></span></div>

        <label>email</label>
        <div class="info">{{ dotcenyOrgan.email | email }}</div>
      </div>
    </div>

    <gmt-navbar [tabs]="tabs" [isActiveTab]="isActiveTab">
      <div *ngIf="tab === 'ukony'">
        <div [loading]="ukonyList.loading">
          <table-ukony (sort)="listService.sort(ukonyList, $event.column, $event.ascOrDesc)"
                       [list]="ukonyList"
                       [customColumns]="['reseneUkony', 'odbor', 'kontakt', 'uradovna']"
                       [clickRow]="false"></table-ukony>
        </div>
      </div>
      <div *ngIf="tab === 'kontakty'">
        <div class="clearfix">
          <div class="fr" *ngIf="canEdit()">
            <a (click)="onKontaktniOsobaCreate()" class="button">
              <i class="fa fa-plus-square"></i> Založit novou kontaktní osobu
            </a>
          </div>
        </div>
        <table-kontaktni-osoby (onChanged)="loadDotcenyOrgan()"
                               [akceSchema]="akceSchema"
                               [dotcenyOrganId]="dotcenyOrgan.id"
                               [kontaktniOsoby]="dotcenyOrgan.kontaktniOsoby">
        </table-kontaktni-osoby>
      </div>

      <div *ngIf="tab === 'odbory'">
        <div class="clearfix pb-20">
          <div class="fr" *ngIf="canEdit()">
            <a (click)="onOdborCreate()" class="button">
              <i class="fa fa-plus-square"></i> Založit nový odbor
            </a>
          </div>
        </div>
        <div class="empty-info cb bordered" *ngIf="dotcenyOrgan && !dotcenyOrgan.odbory.length">Nejsou zadány odbory</div>
        <table-odbor (onChanged)="loadDotcenyOrgan()"
                     *ngFor="let odbor of dotcenyOrgan.odbory"
                     [akceSchema]="akceSchema"
                     [dotcenyOrganId]="dotcenyOrgan.id"
                     [odbor]="odbor"
                     class="cb">
        </table-odbor>
      </div>

      <div *ngIf="tab === 'uradovny'">
        <div class="fr mb-10" *ngIf="canEdit()">
          <a (click)="onAddNewPracoviste()" class="button">
            <i class="fa fa-plus-square"></i> Založit nové pracoviště
          </a>
        </div>
        <table class="bordered">
          <thead><tr><th>Adresa</th><th>Další informace</th><th *ngIf="canEdit()"></th></tr></thead>
          <tr *ngFor="let pracoviste of dotcenyOrgan.pracoviste">
            <td>
              <div>{{ pracoviste.nazev }}</div>
              {{ pracoviste.adresniMisto | address }}
            </td>
            <td><ng-container *ngIf="pracoviste.openHours">
              <div>Úřední hodiny:</div>
              <ng-container *ngFor="let openHour of pracoviste.openHours">
                <div>{{ openHour | openHour }}</div>
              </ng-container>
            </ng-container></td>
            <td *ngIf="canEdit()">
              <div class="fr">
                <a *ngIf="!pracoviste.fromGeopas && showCancelButton(pracoviste)" (click)="onPracovisteCancel(pracoviste)" class="button red mr-5">
                  <i class="fa fa-trash-o"></i>
                </a>
                <a (click)="onPracovisteUpdate(pracoviste)" *ngIf="!pracoviste.fromGeopas" class="button">
                  <i class="fa fa-pencil"></i>
                </a>
              </div>
            </td></tr>
        </table>
      </div>
    </gmt-navbar>
  </div>
</div>
<help [helpId]="helpIds.DOSS_ORGANY_DETAIL" class="cm-help-page"></help>
