import { Component, Inject, Input, OnInit } from '@angular/core';

@Component({
  selector: 'exports',
  templateUrl: './exports.component.html',
})
export class ExportsComponent implements OnInit {

  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
