<div class="list">
  <div class="actions">
    <a (click)="handleUkonCreate()" *ngIf="canCreate()" class="button"><i class="fa fa-wrench"></i>Založit nový úkon DOSS</a>
  </div>

  <h1>Úkony DOSS</h1>
  <ng-container *ngIf="list">
    <filter (onChanged)="pageableList.onFilterChanged($event)" [list]="list">
      <gmt-select
        [(selectAll)]="list.filter.filters.dotcenyOrgan.negation"
        [data]="list.filter.filters.dotcenyOrgan"
        [globalFilterConfig]="globalFilter"
        [restangularService]="restangular"
        resource="dotcenyOrgan/list"
        [filter]="{ filters: { searchText: { values: [] }}}"
        [multiselect]="true"
        [itemPipe]="dotcenyOrganPipe"
        field="values"
        enableSelectAll="true"
        selectTitle="Dotčený orgán"
      ></gmt-select>

      <gmt-select
        [(selectAll)]="list.filter.filters.typUkonu.negation"
        [data]="list.filter.filters.typUkonu"
        [globalFilterConfig]="globalFilter"
        [itemPipe]="typUkonuPipe"
        [multiselect]="true"
        [optionItems]="typyUkonu"
        enableSelectAll="true"
        field="values"
        selectTitle="Typ úkonu"
      ></gmt-select>

      <gmt-select
        [(selectAll)]="list.filter.filters.stavyFaze.negation"
        [data]="list.filter.filters.stavyFaze"
        [globalFilterConfig]="globalFilter"
        [itemPipe]="stavFazeUkonuFilterPipe"
        [multiselect]="true"
        [optionItems]="stavyFaze"
        enableSelectAll="true"
        field="values"
        selectTitle="Stav (fáze)"
      ></gmt-select>

      <gmt-select
        [(selectAll)]="list.filter.filters.kategorieDoss.negation"
        [data]="list.filter.filters.kategorieDoss"
        [globalFilterConfig]="globalFilter"
        [itemPipe]="kategorieDossPipe"
        [multiselect]="true"
        [optionItems]="kategorieDoss"
        enableSelectAll="true"
        field="values"
        selectTitle="Kategorie"
      ></gmt-select>

      <gmt-select
        [(selectAll)]="list.filter.filters.platnost.negation"
        [data]="list.filter.filters.platnost"
        [globalFilterConfig]="globalFilter"
        [multiselect]="true"
        [optionItems]="platnost"
        enableSelectAll="true"
        field="values"
        selectTitle="Platnost"
      ></gmt-select>

      <gmt-select
        [(selectAll)]="list.filter.filters.verzePd.negation"
        [data]="list.filter.filters.verzePd"
        [globalFilterConfig]="globalFilter"
        [itemPipe]="verzePdPipe"
        [multiselect]="true"
        [optionItems]="verzePd"
        enableSelectAll="true"
        field="values"
        selectTitle="Verze PD"
      ></gmt-select>

      <gmt-select
        [(selectAll)]="list.filter.filters.souladSPd.negation"
        [data]="list.filter.filters.souladSPd"
        [globalFilterConfig]="globalFilter"
        [multiselect]="true"
        [optionItems]="souladSPd"
        enableSelectAll="true"
        field="values"
        logGa
        selectTitle="Soulad s PD"
      ></gmt-select>

      <div>
        <input [(ngModel)]="list.filter.filters.searchText.values[0].id" autocomplete="off" class="fulltext"
               placeholder="Fultextové hledání..." type="text"/>
      </div>

      <div (click)="onHideDisabledCasesCheckboxClicked()" [ngClass]="{active: checkCases()}" class="tag"
           title="Zobrazení vč. těch ukonu, které jsou ve stavu zrušeno">
        <div>Zobrazit zrušené úkony</div>
      </div>

      <div (click)="onOblibeneCheckboxClicked()" [ngClass]="{active: checkOblibene()}" class="tag"
           title="Zobrazení len oblubene stavy.">
        <div>Zobrazit pouze oblíbené</div>
      </div>
    </filter>

    <div [loading]="list.loading">
      <div *ngIf="list.itemCount && list.list !== null" class="table-top-container">
        <div class="flex1 basis0">
          <gmt-button [clickAction]="onExport" [gmtDisabled]="!exportEnable"><i class="fa fa-download"></i> Exportovat seznam</gmt-button>
        </div>
        <pagescounter (callbackRegister)="pageableList.pagescounterCallbackRegister($event)"
                      *ngIf="list.itemCount && list.list !== null"
                      [list]="list"></pagescounter>
        <div *ngIf="list.itemCount && list.list !== null" class="page-count-select">
          <span>Záznamů na stránce</span>
          <gmt-select (changed)="pageableList.changePageItems()" [data]="pageableList.dataPaging"
                      [optionItems]="pageableList.itemsPerPageItems"
                      class="min-width-unset" field="itemsPerPage"
                      required="true"></gmt-select>
        </div>
      </div>
      <table-ukony (sort)="pageableList.onSort($event.column, $event.ascOrDesc)"
                   [list]="list"></table-ukony>
      <itemscounter (callbackRegister)="pageableList.itemcounterCallbackRegister($event)"
                    *ngIf="list.itemCount && list.list !== null"
                    [list]="list"
                    style="max-width: 100px;"></itemscounter>
      <pagination (callbackRegister)="pageableList.paginationCallbackRegister($event)"
                  *ngIf="list.itemCount && list.list !== null"
                  [list]="list"></pagination>
    </div>
  </ng-container>
</div>
<help [helpId]="helpIds.DOSS_UKON_LIST" class="cm-help-page"></help>
