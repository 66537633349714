import { Pipe, PipeTransform, Injectable } from "@angular/core";

@Pipe({ name: 'urlhighlight' })
export class UrlHighlightPipe implements PipeTransform {

  public static readonly URL_REGEXP = /(((http|ftp|https):\/\/)|(www\.))([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#!-]*[\w@?^=%&/~+#-])?/g;
  public static readonly EMAIL_REGEXP = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;

  transform(text: string) {
    if (text.match(UrlHighlightPipe.URL_REGEXP)) {
      text = text.replace(UrlHighlightPipe.URL_REGEXP, UrlHighlightPipe.urlReplace);
    }
    if (text.match(UrlHighlightPipe.EMAIL_REGEXP)) {
      text = text.replace(UrlHighlightPipe.EMAIL_REGEXP, '<a href="mailto:$&" class="underlined">$&</a>');
    }
    return text;
  }

  private static urlReplace(text) {
    const visibleText = text;
    if (text.indexOf('www') === 0) {
      text = `https://${text}`;
    }
    return `<a href="${text}" class="underlined" target="_blank">${visibleText}</a>`;
  }
}
