import { NgModule } from '@angular/core';

import { CommonModule } from '../common/common.module';
import { DimapCommonModule } from './dimap-common.module';
import { MapModule } from './map/map.module';
import { ParcelsModule } from '@app/dimap/parcels/parcels.module';
import { TitlesModule } from '@app/dimap/titles/titles.module';
import { PropertyStatusModule } from '@app/dimap/property-status/property-status.module';
import { SettingsModule } from '@app/dimap/settings/settings.module';
import { MainComponent } from '@app/dimap/components/main/main.component';
import { Ng2StateDeclaration, UIRouterModule } from '@uirouter/angular';
import { DimapComponent } from '@app/dimap/components/dimap/dimap.component';
import { CustomerEditComponent } from '@app/settings/customers/components/customer-edit/customer-edit.component';
import {ExportsComponent} from "@app/dimap/components/exports/exports.component";

export const dimapState: Ng2StateDeclaration = {
  name: 'dimap',
  abstract: true,
  url: '/dimap',
  component: DimapComponent,
};

export const dimapProjectState: Ng2StateDeclaration = {
  name: 'dimap.project',
  abstract: true,
  url: '/project/{projectKey:di_[^/]*}',
  params: {
    projectKey: {
      squash: true,
    }
  },
  views: {
    'content@dimap': {
      component: MainComponent,
    }
  },
};

export const exportsState: Ng2StateDeclaration = {
  name: 'dimap.project.exports',
  data: {
    title: 'Exporty',
  },
  url: '/exports',
  views: {
    'content@dimap.project': {
      component: ExportsComponent,
    }
  },
};

@NgModule({
  imports: [
    CommonModule,
    DimapCommonModule,
    MapModule,
    ParcelsModule,
    TitlesModule,
    PropertyStatusModule,
    SettingsModule,
    UIRouterModule.forChild({
      states: [
        dimapState, dimapProjectState, exportsState
      ]
    }),
  ],
  providers: [],
  declarations: [
    MainComponent,
    ExportsComponent,
    DimapComponent
  ],
  entryComponents: [
  ],
  exports: [
  ]
})
export class DimapAppModule {}
