import {Component, Input, OnInit} from '@angular/core';
import {StateService} from "@uirouter/angular";
import {UkonModel} from "@app/doss/models/ukon.model";
import {UkonCreateModel} from "@app/doss/models/ukon-create.model";
import {UkonService} from "@app/doss/services/ukon.service";
import {AuthService} from "@app/common/services/auth.service";
import {DotcenyOrganModel} from "@app/doss/models/dotceny-organ.model";
import { HelpService } from '@app/common/services/help.service';
import {ListModel} from "@app/common/models/list.model";
import {ListService} from "@app/common/services/list.service";
import {PodrobneInformaceModel} from "@app/doss/models/podrobne-informace.model";

@Component({
  templateUrl: './ukon-update.component.html',
  styleUrls: ['./ukon-update.component.scss']
})
export class UkonUpdateComponent implements OnInit {
  helpIds = HelpService.HELP_IDS;
  @Input()
  id: number;

  akceSchema: string;
  ukon: UkonModel;
  podrobneInformaceList: ListModel<PodrobneInformaceModel>;
  podrobneInformaceKeys: string[][];

  loaded: boolean = false;

  constructor(private authService: AuthService,
              private ukonService: UkonService,
              private stateService: StateService,
              private listService: ListService,) {
    this.updateUkon = this.updateUkon.bind(this);
  }

  async ngOnInit() {
    this.akceSchema = this.authService.getActualProject().schema;
    await this.loadUkon();
    await this.loadPodrobneInformace();

    if (this.stateService.params.selectedDotcenyOrgan) {
      this.ukon.dotcenyOrgan = this.stateService.params.selectedDotcenyOrgan;
      this.ukon.kontaktniOsoba = null;
      this.ukon.odbor = null;
      this.ukon.pracoviste = null;
    }
    this.loaded = true;
  }

  canEdit(): boolean {
    return this.authService.hasPermissionForDossAction('manage_actions');
  }

  async updateUkon(): Promise<void> {
    await this.ukonService.updatePodrobnosti(this.podrobneInformaceList.list);
    await this.ukonService.update(this.mapToUkonCreateModel(this.ukon))
      .then(() => this.stateService.go('doss.akce.data.ukony.detail', {akceKey: this.akceSchema, id: this.id}));
  }

  private async loadUkon(): Promise<UkonModel> {
    return await this.ukonService.getById(this.akceSchema, this.id).then(ukon => this.ukon = ukon);
  }

  private async loadPodrobneInformace() {
    this.podrobneInformaceList = this.listService.createList(
      `podrobne-informace`,
      {
        filters: {
          ukonId: this.id,
        },
        sortOrder: {sortBy: 'datumZmeny', direction: 'asc'}
      },
    );
    this.listService.fetchResult(this.podrobneInformaceList).then(() => {
      this.podrobneInformaceKeys = this.podrobneInformaceList.list.map(i => Object.keys(i).filter(k => i[k]));
    });
  }

  public podrobneInformaceValid() : boolean {
    return this.podrobneInformaceKeys && this.podrobneInformaceKeys.every((s, i) => {
      return s.every(k => this.podrobneInformaceList.list[i][k]);
    });
  }

  private mapToUkonCreateModel(ukon: UkonModel): UkonCreateModel {
    const {dotcenyOrgan, kategorieDoss, typUkonu, ...otherProperties} = ukon;

    return {
      dotcenyOrganId: dotcenyOrgan.id,
      kategorieDoss: kategorieDoss.map(kategorie => kategorie.id),
      typUkonu: typUkonu.id,
      ...otherProperties
    };
  }
}
