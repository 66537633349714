import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TableServiceDoss {

  private tables: any[] = [
    {
      id: 'akce',
      name: 'Akce',
      columns: [
        {
          name: 'Evidenční číslo',
          id: 'evidencniCislo',
          exportDefinitions: [
            {name: 'Evidenční číslo', key: 'evidencniCislo'}
          ]
        },
        {
          name: 'Název akce',
          id: 'nazevAkce',
          exportDefinitions: [
            {name: 'Název akce', key: 'nazevAkce'}
          ]
        },
        {
          name: 'Stupeň',
          id: 'stupen',
          exportDefinitions: [
            {name: 'Stupeň', key: 'stupen'}
          ]
        },
        {
          name: 'Stav',
          id: 'stav',
          exportDefinitions: [
            {name: 'Stav', key: 'stav', formatByEval: 'listItem.getStav() == null ? "" : listItem.getStav().getDescription()'}
          ]
        }, {
          name: 'Manažer akce',
          id: 'manazerAkce',
          exportDefinitions: [
            {name: 'Manažer akce', key: 'manazerAkce'}
          ]
        }, {
          name: 'Organizační jednotka',
          id: 'organizacniJednotkaCode',
          exportDefinitions: [
            {name: 'Organizační jednotka', key: 'organizacniJednotkaCode'}
          ]
        }, {
          name: 'Zpracovatel',
          id: 'zpracovatel',
          exportDefinitions: [
            {name: 'Zpracovatel', key: 'zpracovatel'}
          ]
        }, {
          name: 'Projektant',
          id: 'projektant',
          exportDefinitions: [
            {name: 'Projektant', key: 'projektant'}
          ]
        }, {
          name: 'Datum spuštění',
          id: 'datumSpusteni',
          exportDefinitions: [
            {name: 'Datum spuštění', key: 'datumSpusteni'}
          ]
        }, {
          name: 'Datum ukončení',
          id: 'datumUkonceni',
          exportDefinitions: [
            {name: 'Datum ukončení', key: 'datumUkonceni'}
          ]
        }, {
          name: 'Datum poslední změny',
          id: 'datumPosledniZmeny',
          exportDefinitions: [
            {name: 'Datum poslední změny', key: 'datumPosledniZmeny'}
          ]
        }, {
          name: 'Autor poslední změny',
          id: 'autorPosledniZmeny',
          exportDefinitions: [
            {name: 'Autor poslední změny', key: 'autorPosledniZmeny', format: '{fullName}'}
          ]
        }
      ]
    },
    {
      id: 'ukony',
      name: 'Ukony',
      columns: [
        {
          name: 'Dotčený orgán',
          id: 'dotcenyOrgan',
          exportDefinitions: [
            {name: 'Dotčený orgán', key: 'dotcenyOrgan'}
          ]
        },
        {
          name: 'Úkon',
          id: 'ukon',
          exportDefinitions: [
            {name: 'Úkon', key: 'ukon'}
          ]
        },
        {
          name: 'Stav (Fáze)',
          id: 'stav',
          exportDefinitions: [
            {name: 'Stav (Fáze)', key: 'stav'}
          ]
        }, {
          name: 'Kategorie',
          id: 'kategorieDoss',
          exportDefinitions: [
            {name: 'Kategorie', key: 'kategorieDoss', format: '({idKategorie}) {description}'}
          ]
        }, {
          name: 'Datum a autor poslední změny',
          id: 'datumAutorPoslednyZmeny',
        }, {
          name: 'Datum poslední změny',
          id: 'datumPoslednyZmeny',
          exportDefinitions: [
            {name: 'Datum poslední změny', key: 'datumPoslednyZmeny'}
          ]
        }, {
          name: 'Autor poslední změny',
          id: 'autorPoslednyZmeny',
          exportDefinitions: [
            {name: 'Datum a autor poslední změny', key: 'autorPosledniZmeny', format: '{fullName}'}
          ]
        }, {
          name: 'Upozornění',
          id: 'upozorneni',
        }, {
          name: 'Úkoly',
          id: 'ukoly',
        }, {
          name: 'Oblíbené',
          id: 'oblibene',
        }, {
          name: 'Další očekávaný termín',
          id: 'dalsiOcekavanyTermin',
        }, {
          name: 'Propojení',
          id: 'propojeni',
          exportDefinitions: [
            {
              name: 'Propojení',
              formatByEval: `listItem.getPropojeni()
                .map(p =>
                  p.getTypPropojeni().getDescription() + " - " +
                  p.getTargetUkon().getDotcenyOrganNazev() + " - " +
                  p.getTargetUkon().getTypUkonu().getDescription() + " (" +
                  p.getTargetUkon().getStavUkonu().getStav().getTitle() +
                  (p.getTargetUkon().getStavUkonu().getFaze() == null ? "" : " - " + p.getTargetUkon().getStavUkonu().getFaze().getTitle()) +
                  ")"
                ).join("\\n") || ""`
            }
          ]
        }, {
          name: 'Platnost do',
          id: 'platnostDo',
          exportDefinitions: [
            {name: 'Platnost do', key: 'platnostDo'}
          ]
        }, {
          name: 'Verze PD',
          id: 'verzePd',
          exportDefinitions: [
            {name: 'Verze PD', key: 'verzePd'}
          ]
        }, {
         name: 'Odbor',
         id: 'odbor',
       },{
          name: 'Úřadovna',
          id: 'uradovna',
        },{
          name: 'Kontakt pro řešený úkon',
          id: 'kontakt',
        },{
          name: 'Řešené úkony',
          id: 'reseneUkony',
        },
      ]
    },
    {
      id: 'propojeneUkony',
      name: 'Propojené úkony',
      columns: [
        {
          name: 'Vazba',
          id: 'vazba',
          exportDefinitions: [
            {name: 'Vazba', key: 'vazba'}
          ]
        },{
          name: 'Úkon',
          id: 'ukon',
          exportDefinitions: [
            {name: 'Propojený úkon', key: 'targetUkon'}
          ]
        },{
          name: 'Důvod propojení',
          id: 'duvodPropojeni',
          exportDefinitions: [
            {name: 'Důvod propojení', key: 'duvodPropojeni'}
          ]
        }
      ]
    },
    {
      id: 'dotceneOrgany',
      name: 'Dotčené orgány',
      columns: [
        {
          name: 'Dotčený orgán',
          id: 'dotcenyOrgan',
          exportDefinitions: [
            {name: 'Dotčený orgán', key: 'dotcenyOrgan'}
          ]
        },
        {
          name: 'Adresa',
          id: 'adresa',
          exportDefinitions: [
            {name: 'Adresa', key: 'adresa'}
          ]
        }
      ]
    },
    {
      id: 'odbor',
      name: 'Odbor',
      columns: [
        {
          name: 'Řešené úkony',
          id: 'reseneUkony',
          exportDefinitions: [
            {name: 'Řešené úkony', key: 'reseneUkony'}
          ]
        },
        {
          name: 'Kontakt pro řešený úkon',
          id: 'kontakt',
          exportDefinitions: [
            {name: 'Kontakt pro řešený úkon', key: 'kontakt'}
          ]
        },
        {
          name: 'Email',
          id: 'email',
          exportDefinitions: [
            {name: 'Email', key: 'email'}
          ]
        },
        {
          name: 'Telefon',
          id: 'telefon',
          exportDefinitions: [
            {name: 'Telefon', key: 'telefon'}
          ]
        },
        {
          name: 'Úřadovna',
          id: 'pracoviste',
          exportDefinitions: [
            {name: 'Úřadovna', key: 'pracoviste'}
          ]
        }
      ]
    },
    {
      id: 'verzePd',
      name: 'Verze projektové dokumentace',
      columns: [
        {
          name: 'Verze PD',
          id: 'cisloVerze',
          exportDefinitions: [
            {name: 'Verze PD', key: 'cisloVerze'}
          ]
        },
        {
          name: 'Platnost od',
          id: 'platnostOd',
          exportDefinitions: [
            {name: 'Platnost od', key: 'platnostOd'}
          ]
        },
        {
          name: 'Platnost do',
          id: 'platnostDo',
          exportDefinitions: [
            {name: 'Platnost do', key: 'platnostDo'}
          ]
        },
        {
          name: 'Popis verze',
          id: 'popisVerze',
          exportDefinitions: [
            {name: 'Popis verze', key: 'popisVerze'}
          ]
        },
        {
          name: 'Autor záznamu',
          id: 'autor',
          exportDefinitions: [
            {name: 'Autor záznamu', key: 'autor'}
          ]
        },
        {
          name: 'Dotčené kategorie DOSS',
          id: 'kategorieDoss',
          exportDefinitions: [
            {name: 'Dotčené kategorie DOSS', key: 'kategorieDoss'}
          ]
        }
      ]
    },
    {
      id: 'kontaktniOsoba',
      name: 'KONTAKTY',
      columns: [
        {
          name: 'Jméno, Příjmení',
          id: 'jmenoPrijmeni',
          exportDefinitions: [
            {name: 'Jméno, Příjmení', key: 'jmenoPrijmeni'}
          ]
        },
        {
          name: 'Kontakt',
          id: 'kontakt',
          exportDefinitions: [
            {name: 'Kontakt', key: 'kontakt'}
          ]
        },
        {
          name: 'Řešené úkony',
          id: 'reseneUkony',
          exportDefinitions: [
            {name: 'Řešené úkony', key: 'reseneUkony'}
          ]
        }
      ]
    }
  ];

  getTables() {
    return this.tables;
  }

  getTableByID(tableID) {
    return this.getTables().find(table => table.id === tableID);
  }

  getColumnById(tableID, columnID) {
    const table = this.getTableByID(tableID);
    return table.columns.find(column => column.id === columnID);
  }
}
