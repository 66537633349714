<div [loading]="podrobneInformaceList.loading">
  <div>
    <ul class="item-grid">
      <li *ngFor="let podrobneInformace of podrobneInformaceList.list" class="item">
        <div>
          <span class="podrobne-informace-span">{{podrobneInformace.stav}}</span>
          <span *ngIf="podrobneInformace.datum">{{podrobneInformace.datum | date}}</span>
          <span *ngIf="!podrobneInformace.datum">{{podrobneInformace.datumZmeny | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.faze" class="podrobne-informace-span">({{podrobneInformace.faze}})</div>
        <div *ngIf="podrobneInformace.datumVydani">
          <span class="podrobne-informace-span">Datum vydání</span>
          <span>{{podrobneInformace.datumVydani | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.datumKoncePlatnosti">
          <span class="podrobne-informace-span">Datum konce platnosti</span>
          <span>{{podrobneInformace.datumKoncePlatnosti | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.lhutaProOdvolani">
          <span class="podrobne-informace-span">Datum lhůty pro odvolání</span>
          <span>{{podrobneInformace.lhutaProOdvolani | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.zpusobOdeslani">
          <span class="podrobne-informace-span">Způsob odeslání</span>
          <span>{{podrobneInformace.zpusobOdeslani.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.dotcenyOrgan">
          <span class="podrobne-informace-span">Dotčený orgán</span>
          <span>{{podrobneInformace.dotcenyOrgan | dotcenyOrgan}}</span>
        </div>
        <div *ngIf="podrobneInformace.termin">
          <span class="podrobne-informace-span">Termín</span>
          <span>{{podrobneInformace.termin.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.duvod">
          <span class="podrobne-informace-span">Důvod</span>
          <span>{{podrobneInformace.duvod}}</span>
        </div>
        <div *ngIf="podrobneInformace.datumJednani">
          <span class="podrobne-informace-span">Datum jednání</span>
          <span>{{podrobneInformace.datumJednani | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.misto">
          <span class="podrobne-informace-span">Místo</span>
          <span>{{podrobneInformace.misto}}</span>
        </div>
        <div *ngIf="podrobneInformace.datumSetreni">
          <span class="podrobne-informace-span">Datum šetření</span>
          <span>{{podrobneInformace.datumSetreni | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.lhuta">
          <span class="podrobne-informace-span">Datum lhůty</span>
          <span>{{podrobneInformace.lhuta | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.zpusobUrgence">
          <span class="podrobne-informace-span">Způsob urgence</span>
          <span>{{podrobneInformace.zpusobUrgence.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.zpusobPodani">
          <span class="podrobne-informace-span">Způsob podání</span>
          <span>{{podrobneInformace.zpusobPodani.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.datumDoruceni">
          <span class="podrobne-informace-span">Datum doručení</span>
          <span>{{podrobneInformace.datumDoruceni | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.omezeniDelkyPlatnostiRozhodnuti">
          <span class="podrobne-informace-span">Datum omezení délky platnosti rozhodnutí</span>
          <span>{{podrobneInformace.omezeniDelkyPlatnostiRozhodnuti | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.dokumentRozhodnuti">
          <span class="podrobne-informace-span">Dokument rozhodnutí</span>
          <span>{{podrobneInformace.dokumentRozhodnuti.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.odvolaniPodal">
          <span class="podrobne-informace-span">Odvolání podal</span>
          <span>{{podrobneInformace.odvolaniPodal}}</span>
        </div>
        <div *ngIf="podrobneInformace.odvolaciOrgan">
          <span class="podrobne-informace-span">Odvolací orgán</span>
          <span>{{podrobneInformace.odvolaciOrgan}}</span>
        </div>
        <div *ngIf="podrobneInformace.dokumentZamitnuti">
          <span class="podrobne-informace-span">Dokument zamítnutí</span>
          <span>{{podrobneInformace.dokumentZamitnuti.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.dokumentZastaveni">
          <span class="podrobne-informace-span">Dokument zastavení</span>
          <span>{{podrobneInformace.dokumentZastaveni.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.dokumentRozhodnutiOodvolani">
          <span class="podrobne-informace-span">Dokument rozhodnutí odvolání</span>
          <span>{{podrobneInformace.dokumentRozhodnutiOodvolani.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.dokumentPotvrzeni">
          <span class="podrobne-informace-span">Dokument potvrzení</span>
          <span>{{podrobneInformace.dokumentPotvrzeni.name}}</span>
        </div>
        <div *ngIf="podrobneInformace.podminkyVydani">
          <span class="podrobne-informace-span">Podmínky vydání</span>
          <span>{{podrobneInformace.podminkyVydani}}</span>
        </div>
        <div *ngIf="podrobneInformace.dokumenty">
          <span class="podrobne-informace-span">Dokument rozhodnutí</span>
          <span *ngFor="let dokument of podrobneInformace.dokumenty">{{dokument.baseName}}{{(dokument.extension ? '.' + dokument.extension : '')}} (id: {{dokument.id}})</span>
        </div>
        <div *ngIf="podrobneInformace.datumFikceSouhlasu">
          <span class="podrobne-informace-span">Datum fikce souhlasu</span>
          <span>{{podrobneInformace.datumFikceSouhlasu | date}}</span>
        </div>
        <div *ngIf="podrobneInformace.poznamka">
          <span class="podrobne-informace-span">Poznámka</span>
          <span>{{podrobneInformace.poznamka}}</span>
        </div>
      </li>
    </ul>
  </div>

  <div class="bordered" *ngIf="podrobneInformaceList.list && podrobneInformaceList.itemCount === 0">
    <div class="empty-info">
      <span>Žádné podrobné informace</span>
    </div>
  </div>
</div>
