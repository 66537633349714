<div class="detail">
  <div>
    <a class="button mb-10" uiSref="^"><i class="fa fa-chevron-left mr-5"></i>Zpět na složku úkonu DOSS</a>
  </div>
  <h1>Nastavení úkonu DOSS</h1>

  <div *ngIf="loaded">
    <wizard [submitButtonName]="'Uložit'" [submitAction]="updateUkon" *ngIf="canEdit()">
      <wizard-tab [isValid]="ukonForm.isValid() && podrobneInformaceValid()">
        <ukon-form #ukonForm
                   [isUpdate]="true"
                   redirectToPageAfterDossSave="doss.akce.data.ukony.detail.update"
                   [redirectToPageParamsAfterDossSave]="{id: id}"
                   [ukon]="ukon">
        </ukon-form>

        <div *ngFor="let podrobneInformace of podrobneInformaceList.list; index as i" class="column-100">
          <div *ngIf="podrobneInformaceKeys[i].includes('datum')">
            <label style="width:300px" class="required">{{podrobneInformace.stav}}
              <ng-container *ngIf="podrobneInformace.faze">({{podrobneInformace.faze}})</ng-container>
            </label>
            <div class="input"><input pick-a-date type="text" [(ngModel)]="podrobneInformace.datum"/></div>
          </div>
          <div *ngIf="podrobneInformaceKeys[i].includes('datumVydani')">
            <label style="width:300px" class="required">Datum vydání</label>
            <div class="input"><input pick-a-date type="text" [(ngModel)]="podrobneInformace.datumVydani"/></div>
          </div>
          <div *ngIf="podrobneInformaceKeys[i].includes('datumKoncePlatnosti')">
            <label style="width:300px" class="required">Datum konce platnosti</label>
            <div class="input"><input pick-a-date type="text" [(ngModel)]="podrobneInformace.datumKoncePlatnosti"/></div>
          </div>
          <div *ngIf="podrobneInformaceKeys[i].includes('lhutaProOdvolani')">
            <label style="width:300px" class="required">Lhůta pro odvolání</label>
            <div class="input"><input pick-a-date type="text" [(ngModel)]="podrobneInformace.lhutaProOdvolani"/></div>
          </div>
          ppp
          <div *ngIf="podrobneInformaceKeys[i].includes('zpusobOdeslani')">
            <label style="width:300px" class="required">Způsob odeslání</label>
            <span>{{podrobneInformace.zpusobOdeslani.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.dotcenyOrgan">
            <span class="podrobne-informace-span">Dotčený orgán</span>
            <span>{{podrobneInformace.dotcenyOrgan | dotcenyOrgan}}</span>
          </div>
          <div *ngIf="podrobneInformace.termin">
            <span class="podrobne-informace-span">Termín</span>
            <span>{{podrobneInformace.termin.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.duvod">
            <span class="podrobne-informace-span">Důvod</span>
            <span>{{podrobneInformace.duvod}}</span>
          </div>
          <div *ngIf="podrobneInformace.datumJednani">
            <span class="podrobne-informace-span">Datum jednání</span>
            <span>{{podrobneInformace.datumJednani | date}}</span>
          </div>
          <div *ngIf="podrobneInformace.misto">
            <span class="podrobne-informace-span">Místo</span>
            <span>{{podrobneInformace.misto}}</span>
          </div>
          <div *ngIf="podrobneInformace.datumSetreni">
            <span class="podrobne-informace-span">Datum šetření</span>
            <span>{{podrobneInformace.datumSetreni | date}}</span>
          </div>
          <div *ngIf="podrobneInformace.lhuta">
            <span class="podrobne-informace-span">Datum lhůty</span>
            <span>{{podrobneInformace.lhuta | date}}</span>
          </div>
          <div *ngIf="podrobneInformace.zpusobUrgence">
            <span class="podrobne-informace-span">Způsob urgence</span>
            <span>{{podrobneInformace.zpusobUrgence.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.zpusobPodani">
            <span class="podrobne-informace-span">Způsob podání</span>
            <span>{{podrobneInformace.zpusobPodani.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.datumDoruceni">
            <span class="podrobne-informace-span">Datum doručení</span>
            <span>{{podrobneInformace.datumDoruceni | date}}</span>
          </div>
          <div *ngIf="podrobneInformace.omezeniDelkyPlatnostiRozhodnuti">
            <span class="podrobne-informace-span">Datum omezení délky platnosti rozhodnutí</span>
            <span>{{podrobneInformace.omezeniDelkyPlatnostiRozhodnuti | date}}</span>
          </div>
          <div *ngIf="podrobneInformace.dokumentRozhodnuti">
            <span class="podrobne-informace-span">Dokument rozhodnutí</span>
            <span>{{podrobneInformace.dokumentRozhodnuti.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.odvolaniPodal">
            <span class="podrobne-informace-span">Odvolání podal</span>
            <span>{{podrobneInformace.odvolaniPodal}}</span>
          </div>
          <div *ngIf="podrobneInformace.odvolaciOrgan">
            <span class="podrobne-informace-span">Odvolací orgán</span>
            <span>{{podrobneInformace.odvolaciOrgan}}</span>
          </div>
          <div *ngIf="podrobneInformace.dokumentZamitnuti">
            <span class="podrobne-informace-span">Dokument zamítnutí</span>
            <span>{{podrobneInformace.dokumentZamitnuti.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.dokumentZastaveni">
            <span class="podrobne-informace-span">Dokument zastavení</span>
            <span>{{podrobneInformace.dokumentZastaveni.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.dokumentRozhodnutiOodvolani">
            <span class="podrobne-informace-span">Dokument rozhodnutí odvolání</span>
            <span>{{podrobneInformace.dokumentRozhodnutiOodvolani.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.dokumentPotvrzeni">
            <span class="podrobne-informace-span">Dokument potvrzení</span>
            <span>{{podrobneInformace.dokumentPotvrzeni.name}}</span>
          </div>
          <div *ngIf="podrobneInformace.podminkyVydani">
            <span class="podrobne-informace-span">Podmínky vydání</span>
            <span>{{podrobneInformace.podminkyVydani}}</span>
          </div>
          <div *ngIf="podrobneInformace.dokumenty">
            <span class="podrobne-informace-span">Dokument rozhodnutí</span>
            <span *ngFor="let dokument of podrobneInformace.dokumenty">{{dokument.baseName}}{{(dokument.extension ? '.' + dokument.extension : '')}} (id: {{dokument.id}})</span>
          </div>
          <div *ngIf="podrobneInformace.datumFikceSouhlasu">
            <span class="podrobne-informace-span">Datum fikce souhlasu</span>
            <span>{{podrobneInformace.datumFikceSouhlasu | date}}</span>
          </div>
          <div *ngIf="podrobneInformace.poznamka">
            <span class="podrobne-informace-span">Poznámka</span>
            <span>{{podrobneInformace.poznamka}}</span>
          </div>
        </div>
      </wizard-tab>
    </wizard>
  </div>
</div>
<help [helpId]="helpIds.DOSS_UKON_UPDATE" class="cm-help-page"></help>
